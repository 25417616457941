// :root CSS dark variables

[data-layout-mode="dark"] {
	// Color system - Dark Mode only
	$gray-100: #1a1d21;
	$gray-200: #212529;
	$gray-300: #2a2f34;
	$gray-400: #878a99;
	$gray-500: #adb5bd;
	$gray-600: #bfc8e2;
	$gray-700: #ced4da;
	$gray-800: #eff2f7;
	$gray-900: #f3f6f9;

	$grays: (
		"100": $gray-100,
		"200": $gray-200,
		"300": $gray-300,
		"400": $gray-400,
		"500": $gray-500,
		"600": $gray-600,
		"700": $gray-700,
		"800": $gray-800,
		"900": $gray-900,
	);

	// Prefix for :root CSS variables
	@each $color, $value in $grays {
		--#{$prefix}gray-#{$color}: #{$value};
	}

	// body
	--#{$prefix}body-bg: #{$gray-100};
	--#{$prefix}body-bg-rgb: #{to-rgb($gray-100)};
	--#{$prefix}body-color: #{$gray-700};
	--#{$prefix}body-color-rgb: #{to-rgb($gray-700)};

	//vertical light
	--#{$prefix}vertical-menu-bg: #{$white};
	--#{$prefix}vertical-menu-item-color: #{darken($gray-400, 16%)};
	--#{$prefix}vertical-menu-item-hover-color: #{$primary};
	--#{$prefix}vertical-menu-item-active-color: #{$primary};
	--#{$prefix}vertical-menu-sub-item-color: #{darken($gray-400, 12%)};
	--#{$prefix}vertical-menu-sub-item-hover-color: #{$primary};
	--#{$prefix}vertical-menu-sub-item-active-color: #{$primary};
	--#{$prefix}vertical-menu-title-color: #{$gray-400};

	//vertical dark
	--#{$prefix}vertical-menu-bg-dark: #{$gray-200};
	--#{$prefix}vertical-menu-item-color-dark: #{$gray-400};
	--#{$prefix}vertical-menu-item-hover-color-dark: #{$white};
	--#{$prefix}vertical-menu-item-active-color-dark: #{$white};
	--#{$prefix}vertical-menu-sub-item-color-dark: #{darken($gray-400, 4%)};
	--#{$prefix}vertical-menu-sub-item-hover-color-dark: #{$white};
	--#{$prefix}vertical-menu-sub-item-active-color-dark: #{$white};
	--#{$prefix}vertical-menu-title-color-dark: #{darken($gray-400, 16%)};

	// Topbar - (Default Light)
	--#{$prefix}header-bg: #{lighten($gray-200, 1%)};
	--#{$prefix}header-item-color: #e9ecef;
	// Topbar - Dark
	--#{$prefix}header-bg-dark: #{$primary};
	--#{$prefix}header-item-color-dark: #{rgba($white, 0.6)};

	// Topbar Search
	--#{$prefix}topbar-search-bg: #{darken($gray-200, 3%)};
	// Topbar User
	--#{$prefix}topbar-user-bg: #{lighten($gray-300, 2%)};
	--#{$prefix}topbar-user-bg-dark: #{lighten($primary, 3%)};

	//footer
	--#{$prefix}footer-bg: #{$gray-200};
	--#{$prefix}footer-color: #{$gray-400};

	// Horizontal nav
	--#{$prefix}topnav-bg: #{lighten($gray-200, 2.5%)};
	--#{$prefix}topnav-item-color: #{$gray-400};
	--#{$prefix}topnav-item-color-active: #{$white};

	// twocolumn menu
	--#{$prefix}twocolumn-menu-iconview-bg: #{$white};
	--#{$prefix}twocolumn-menu-bg: #{$white};

	// two column dark
	--#{$prefix}twocolumn-menu-iconview-bg-dark: #{darken($gray-200, 2.5%)};
	--#{$prefix}twocolumn-menu-bg-dark: var(--#{$prefix}vertical-menu-bg-dark);
	--#{$prefix}twocolumn-menu-item-color-dark: var(--#{$prefix}vertical-menu-item-color-dark);
	--#{$prefix}twocolumn-menu-item-active-color-dark: #{$white};
	--#{$prefix}twocolumn-menu-item-active-bg-dark: #{rgba($white, 0.15)};

	// boxed
	--#{$prefix}boxed-body-bg: #{darken($gray-100, 4%)};

	// heading-color
	--#{$prefix}heading-color: #{$gray-700};

	// component variable
	--#{$prefix}light: #{$gray-300};
	--#{$prefix}light-rgb: #{to-rgb($gray-300)};
	--#{$prefix}dark: #{$gray-800};
	--#{$prefix}dark-rgb: #{to-rgb($gray-800)};

	// link
	--#{$prefix}link-color: #{$gray-700};
	--#{$prefix}link-hover-color: #{$gray-700};

	// Border variable
	--#{$prefix}border-color: #{lighten($gray-300, 3.5%)};

	// dropdown
	.dropdown-menu {
		--#{$prefix}dropdown-bg: #{lighten($gray-200, 3.5%)};
		--#{$prefix}dropdown-link-color: #{$gray-500};
		--#{$prefix}dropdown-link-hover-color: #{shade-color($gray-700, 10%)};
		--#{$prefix}dropdown-link-hover-bg: #{lighten($gray-300, 2%)};
		--#{$prefix}dropdown-border-width: 1px;
		--#{$prefix}dropdown-link-active-bg: #{lighten($gray-300, 2%)};
		--#{$prefix}dropdown-link-active-color: #{$gray-500};
	}

	//choices
	.choices,
	.flatpickr-calendar,
	.ck,
	.ql-toolbar,
	.select2-container,
	.menu-dropdown {
		--#{$prefix}choices-bg: #{lighten($gray-200, 3.5%)};
		--#{$prefix}choices-link-color: #{$gray-500};
		--#{$prefix}choices-link-hover-bg: #{lighten($gray-300, 2%)};
		--#{$prefix}choices-link-active-color: #{shade-color($gray-900, 10%)};
	}

	//buttons
	.btn-light {
		--#{$prefix}btn-color: var(--#{$prefix}dark);
		--#{$prefix}btn-bg: var(--#{$prefix}light);
		--#{$prefix}btn-border-color: var(--#{$prefix}light);
	}

	.btn-outline-light {
		--#{$prefix}btn-color: var(--#{$prefix}dark);
		--#{$prefix}btn-bg: transparent;
		--#{$prefix}btn-border-color: var(--#{$prefix}light);
	}

	.btn-light,
	.btn-outline-light {
		--#{$prefix}btn-hover-color: var(--#{$prefix}dark);
		--#{$prefix}btn-hover-bg: rgba(var(--#{$prefix}light-rgb), 0.75);
		--#{$prefix}btn-hover-border-color: rgba(var(--#{$prefix}light-rgb), 0.75);
	}

	.btn-dark {
		--#{$prefix}btn-color: var(--#{$prefix}light);
		--#{$prefix}btn-bg: var(--#{$prefix}dark);
		--#{$prefix}btn-border-color: var(--#{$prefix}dark);
	}

	.btn-outline-dark {
		--#{$prefix}btn-color: var(--#{$prefix}dark);
		--#{$prefix}btn-bg: transparent;
		--#{$prefix}btn-border-color: var(--#{$prefix}dark);
	}

	.btn-dark,
	.btn-outline-dark {
		--#{$prefix}btn-hover-color: var(--#{$prefix}light);
		--#{$prefix}btn-hover-bg: rgba(var(--#{$prefix}dark-rgb), 0.75);
		--#{$prefix}btn-hover-border-color: rgba(var(--#{$prefix}dark-rgb), 0.75);
	}

	// card
	.card {
		--#{$prefix}card-bg: #{$gray-200};
		--#{$prefix}card-cap-bg: #{$gray-200};
	}

	--#{$prefix}card-bg-custom: #{$gray-200};
	--#{$prefix}card-logo-dark: none;
	--#{$prefix}card-logo-light: block;

	// modal
	.modal {
		--#{$prefix}modal-bg: #{$gray-200};
	}

	// nav tabs
	.nav-tabs {
		--#{$prefix}nav-tabs-link-active-color: #{$gray-900};
		--#{$prefix}nav-tabs-link-active-bg: #{$gray-300};
	}

	// accordion
	.accordion {
		--#{$prefix}accordion-button-active-color: #{$white};
		--#{$prefix}accordion-bg: #{$gray-200};
		--#{$prefix}accordion-btn-bg: #{$gray-200};
	}

	//offcanvas
	.offcanvas,
	.offcanvas-lg,
	.offcanvas-md,
	.offcanvas-sm,
	.offcanvas-xl,
	.offcanvas-xxl {
		--#{$prefix}offcanvas-bg: #{$gray-200};
	}

	// progress
	.progress {
		--#{$prefix}progress-bg: #{$gray-300};
	}

	//table
	.table {
		--vz-table-striped-bg: #{rgba($white, 0.03)};
		--vz-table-active-bg: #{rgba($white, 0.07)};
		--vz-table-hover-bg: #{rgba($white, 0.07)};
	}

	.table-light {
		--vz-table-color: #{$white};
		--vz-table-bg: #{rgba($white, 0.05)};
		--vz-table-border-color: #{$border-color};
		--vz-table-striped-bg: #{rgba($white, 0.07)};
		--vz-table-striped-color: #{$white};
	}

	// toast
	.toast {
		--#{$prefix}toast-bg: #{rgba($gray-300, 0.85)};
		--#{$prefix}toast-header-bg: #{rgba($gray-300, 0.85)};
		--#{$prefix}toast-border-color: #{rgba($white, 0.1)};
		--#{$prefix}toast-header-border-color: #{rgba($white, 0.05)};
	}

	//list
	--#{$prefix}list-group-hover-bg: #{lighten($gray-200, 2.5%)};

	// popover
	.popover {
		--#{$prefix}popover-bg: #{lighten($gray-200, 1%)};
	}

	// pagination
	.pagination {
		--#{$prefix}pagination-hover-bg: #{lighten($gray-200, 4%)};
		--#{$prefix}pagination-focus-bg: #{lighten($gray-200, 4%)};
	}

	//form
	--#{$prefix}input-bg: #{lighten($gray-200, 2%)};
	--#{$prefix}input-border: #{$gray-300};
	--#{$prefix}input-focus-border: #{lighten($gray-300, 4%)};
	--#{$prefix}input-disabled-bg: #{$gray-200};

	// input-group-addon
	--#{$prefix}input-group-addon-bg: #{$gray-300};

	//check
	--#{$prefix}input-check-border: #{lighten($gray-300, 4%)};

	//---------------------------------------//
	//Theme Dark
	.dark-text-white {
		color: white !important;
	}

	.dark-text-dark {
		color: #000000 !important;
	}

	.popover-body {
		background-color: #212529 !important;
	}

	.react-tags__search-input {
		background-color: #212529 !important;
		color: #ffffff !important;
	}
	.bg-theme-dark,
	.display-error {
		background-color: #212529 !important;
	}
	.selectMerge {
		background-color: #223141 !important;
	}
	.selectRowData {
		background-color: #536884 !important;
		color: #000000 !important;
	}

	.MuiFormLabel-root {
		padding: 1px 0px 6px 6px !important;
		font-size: 0.825rem !important;
		color: #878a99 !important;
	}
	.MuiInputBase-input {
		font-size: 0.825rem !important;
		color: #ced4da !important;
	}
	.MuiOutlinedInput-notchedOutline {
		border-color: #32383e;
	}

	.css-wjsjww-MuiChip-label {
		color: #ced4da;
	}
	.css-1kp1dnc-MuiButtonBase-root-MuiChip-root {
		background-color: #ffffff14 !important;
	}
	.css-1kp1dnc-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon {
		color: #ced4da;
	}

	.backgroud-search-vpn {
		background-color: #1a1d21;
	}
	.title-table-vpn {
		font-size: 0.825rem;
		font-weight: bold;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 100%;
		text-align: center;
	}

	.new-item-title {
		color: rgb(255, 255, 255);
		font-size: 1rem;
	}
	.new-item-seller {
		color: rgb(240, 242, 247);
		font-weight: 700;
		font-size: 1rem;
	}
	.rti--tag span {
		color: #1a1d21;
	}
	.title-table-market {
		font-size: 0.825rem;
		font-weight: bold;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 100%;
		text-align: center;
		z-index: 1;
		position: inherit;
		margin-top: 4px;
	}

	// .react-select__value-container {
	// 	background-color: #1a1d21 !important;
	// 	color: rgb(240, 242, 247) !important;
	// }

	// .react-select__control--is-focused {
	// 	color: #1a1d21 !important;
	// }
	// .react-select__option:focus {
	// 	color: #1a1d21 !important;
	// }

	// .react-select__option .react-select__control--is-focused:first-child {
	// 	color: #1a1d21 !important;
	// }
	.react-select__option--is-focused {
		background-color: rgb(240, 242, 247) !important;
		color: black !important;
	}

	//react-strap
	.react-select__indicators,
	.react-select__control,
	.react-select__value-container {
		background-color: #1a1d21 !important;
	}
	.react-select__menu {
		background-color: #1a1d21 !important;
	}
	.react-select__option:hover {
		background-color: rgb(240, 242, 247) !important;
		color: #1a1d21 !important;
	}
	.react-select__single-value {
		color: rgb(240, 242, 247);
	}
	.react-select__input-container {
		color: #ffffff;
	}

	//Multi line
	.react-select-multi-line__option--is-focused {
		background-color: rgb(240, 242, 247) !important;
		color: black !important;
	}
	//react-strap
	.react-select-multi-line__indicators,
	.react-select-multi-line__control,
	.react-select-multi-line__value-container {
		background-color: #1a1d21 !important;
	}
	.react-select-multi-line__menu {
		background-color: #1a1d21 !important;
	}
	.react-select-multi-line__option:hover {
		background-color: rgb(240, 242, 247) !important;
		color: #1a1d21 !important;
	}
	.react-select-multi-line__single-value {
		color: rgb(240, 242, 247);
	}
	.react-select-multi-line__input-container {
		color: #ffffff;
	}

	//treeselect
	.ant-select-tree-switcher,
	.ant-select-tree-switcher_open {
		color: rgb(240, 242, 247) !important;
	}

	.ant-select-dropdown {
		border: 1px solid #ccc;
		border-radius: 5px;
		box-shadow: none;
		background-color: #1a1d21 !important;
	}
	.ant-select-tree-list-holder-inner {
		background-color: #1a1d21 !important;
	}
	.ant-select-tree-node-content-wrapper {
		color: rgb(240, 242, 247);
		// font-weight: bold;
	}

	.ant-select-tree-treenode,
	.ant-select-tree-treenode-switcher-open,
	.ant-select-tree-treenode-selected .ant-select-tree-title {
		color: #1a1d21;
	}

	.ant-select-tree-title {
		color: rgb(240, 242, 247);
	}

	.ant-select-selector {
		background-color: #1a1d21 !important;
	}

	.ant-select-selection-search {
		color: rgb(240, 242, 247) !important;
	}
	.ant-select-selection-item {
		color: rgb(240, 242, 247) !important;
	}
	.ant-select-arrow {
		color: rgb(240, 242, 247) !important;
	}
	.ant-select-arrow:hover {
		background-color: #1a1d21 !important;
	}
	.ant-select-selection-placeholder {
		background-color: #1a1d21 !important;
	}
	.ant-tree-select {
		background-color: #1a1d21 !important;
	}
	.ant-select-clear {
		background-color: #1a1d21 !important;
		color: rgb(240, 242, 247) !important;
	}

	.ant-select-selection-selected-value {
		color: rgb(240, 242, 247) !important;
	}

	.accordion-button {
		background-color: #1a1d21 !important;
		color: rgb(240, 242, 247) !important;
	}
}

//sidebar color
[data-sidebar="gradient"] {
	--#{$prefix}vertical-menu-bg-gradient: linear-gradient(to right, var(--#{$prefix}primary), var(--#{$prefix}success));
	--#{$prefix}vertical-menu-border-gradient: var(--#{$prefix}success);
	--#{$prefix}twocolumn-menu-bg-dark: var(--#{$prefix}success);
}

[data-sidebar="gradient-2"] {
	--#{$prefix}vertical-menu-bg-gradient: linear-gradient(to right, var(--#{$prefix}info), var(--#{$prefix}secondary));
	--#{$prefix}vertical-menu-border-gradient: var(--#{$prefix}secondary);
	--#{$prefix}twocolumn-menu-bg-dark: var(--#{$prefix}secondary);
}

[data-sidebar="gradient-3"] {
	--#{$prefix}vertical-menu-bg-gradient: linear-gradient(to right, var(--#{$prefix}info), var(--#{$prefix}success));
	--#{$prefix}vertical-menu-border-gradient: var(--#{$prefix}success);
	--#{$prefix}twocolumn-menu-bg-dark: var(--#{$prefix}success);
}

[data-sidebar="gradient-4"] {
	--#{$prefix}vertical-menu-bg-gradient: linear-gradient(to right, #363d48, var(--#{$prefix}primary));
	--#{$prefix}vertical-menu-border-gradient: var(--#{$prefix}primary);
	--#{$prefix}twocolumn-menu-bg-dark: var(--#{$prefix}primary);
}
