.rc-tooltipCustom {
	.rc-tooltip-inner {
		background-color: #4a4a4a !important;
		padding: 0px 8px !important;
		color: #fff;
		text-align: left;
		text-decoration: none;
		background-color: #565656;
		border-radius: 4px !important;
		box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
		min-height: 25px !important;
		display: flex;
		align-items: center;
	}
	.rc-tooltip-arrow {
		// background-color: #ffcc00 !important;
		// color: #ffcc00 !important;
		display: none;
	}
}
