// custom scrollbar
.scrollbarCustom {
	&::-webkit-scrollbar {
		width: 5px; // scrollX
		height: 10px; // scrollY
	}
	&::-webkit-scrollbar-track {
		// -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.9);
		// background: #f3f6f9;
		border-radius: 10px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background: #bebebe;
	}
}
