/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Custom Css File
*/

.css-b62m3t-container {
	.css-1s2u09g-control {
		// background-color: var(--#{$prefix}gray-200);
		// border-color: var(--#{$prefix}border-color);
		// &:hover {
		//     border-color: var(--#{$prefix}border-color);
		// }
	}
	.css-1pahdxg-control {
		// background-color: var(--#{$prefix}gray-200);
		// border-color: var(--#{$prefix}border-color);
		// box-shadow: none;
		// &:hover {
		//     border-color: var(--#{$prefix}border-color) !important;
		// }
	}
	.css-26l3qy-menu {
		background-color: $dropdown-bg;
		.css-1n7v3ny-option {
			cursor: pointer;
			background-color: $dropdown-link-hover-bg;
			&:active {
				background-color: $dropdown-link-hover-bg;
			}
		}
	}
	.css-1rhbuit-multiValue {
		background-color: var(--#{$variable-prefix}light);
	}
	.css-12jo7m5 {
		color: $heading-color;
	}
	.css-6j8wv5-Input,
	.css-qc6sy-singleValue {
		color: $heading-color;
	}
	// .css-9gakcf-option {
	//     background-color: #DEEBFF;
	// }
}

.marketplace-swiper .swiper-button-next,
.marketplace-swiper .swiper-button-prev {
	top: 24px;
}

.marquee {
	width: 100%;
	overflow: hidden;
	position: relative;
	height: 22px;
	line-height: 2;
}

.marquee div {
	display: block;
	width: 200%;
	height: 30px;
	position: absolute;
	overflow: hidden;
	animation: marquee 35s linear infinite;
}

.marquee span {
	float: left;
	width: 50%;
}

@keyframes marquee {
	0% {
		left: 0;
	}

	100% {
		left: -100%;
	}
}

.autocomplete-input-option {
	font-size: 12px !important;
	font-weight: 500 !important;
}
.autocomplete-input-option-detail {
	font-size: 11px !important;
	font-style: italic;
}

.height-box-vpn {
	min-height: calc(16vh);
}

.height-box-detail-vpn {
	min-height: calc(40vh);
	max-height: calc(40vh);
}

.height-box-detail-email {
	min-height: calc(34.5vh);
	max-height: calc(34.5vh);
}

.height-box-detail-vpn-xero {
	min-height: calc(45vh);
	max-height: calc(45vh);
}

.box-vpn {
	box-shadow: none;
}

[data-layout="horizontal"] .menu-dropdown {
	min-width: 13rem;
}

.MuiAutocomplete-input {
	padding: 4px 0px 2px 6px !important;
}
.textfield-input {
	padding: 10px 14px 8.5px 14px !important;
}

.react-select-multi-line__menu {
	z-index: 2 !important;
}

.react-select__value-container {
	flex-wrap: nowrap !important;
}

$error-color: #e63946;
$primary-color: #1d3557;
.input-error {
	border-color: $error-color;
}

.error {
	color: $error-color;
	width: 100%;
	margin-top: 0.25rem;
	font-size: 0.875em;
}
.disabled-btn {
	cursor: not-allowed;
	background: rgba($primary-color, 0.8);
}
.item-option-search-total {
	&:hover {
		background: #f6f6f8 !important;
	}
	.disabled-btn {
		cursor: not-allowed;
		background: rgba($primary-color, 0.8);
	}
}

.new-item {
	transition: all 0.2s ease-in-out;
	&:hover {
		transform: scale(1.02);
	}
}

.d-grid {
	display: grid;
}

.justify-seft-center {
	justify-self: center;
}

//React Tag Input
/* Example Styles for React Tags*/
#app {
	padding: 40px;
}

div.ReactTags__tags {
	position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
	width: 100%;
	border-radius: 3px;
	display: inline-block;
	// margin-left: 3px;
}
div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
	// height: 31px;
	// margin: 0;
	// width: 100%;
	// border: 1px solid #eee;
	// padding: 0 4px;
	// border-radius: 3px;

	display: block;
	width: 100%;
	padding: $input-padding-y $input-padding-x;
	font-family: $input-font-family;

	font-weight: $input-font-weight;
	line-height: $input-line-height;
	color: $input-color;
	background-color: $input-bg;
	background-clip: padding-box;
	border: $input-border-width solid $input-border-color;
	appearance: none; // Fix appearance for date inputs in Safari
	border-radius: 0.25rem;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
	border: 1px solid #ddd;
	background: rgba(102, 145, 231, 0.3);
	font-size: 12px;
	display: inline-block;
	padding: 7px;
	// margin: 0 3px;
	margin-right: 5px;
	margin-top: 5px;
	cursor: move;
	border-radius: 5px;
}
div.ReactTags__selected a.ReactTags__remove {
	color: #aaa;
	margin-left: 5px;
	cursor: pointer;
}

.ReactTags__remove {
	border: none;
	background: none;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
	position: absolute;
}
div.ReactTags__suggestions ul {
	list-style-type: none;
	box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
	background: white;
	width: 200px;
}
div.ReactTags__suggestions li {
	border-bottom: 1px solid #ddd;
	padding: 5px 10px;
	margin: 0;
}
div.ReactTags__suggestions li mark {
	text-decoration: underline;
	background: none;
	font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
	background: #b7cfe0;
	cursor: pointer;
}

.rti--container {
	--rti-bg: #fff;
	--rti-border: #ccc;
	--rti-main: #3182ce;
	--rti-radius: 0.375rem;
	--rti-s: 0.5rem;
	--rti-tag: #edf2f7;
	--rti-tag-remove: #e53e3e;
	display: inline-block;
	line-height: 1.2;
}

.react-select__menu {
	z-index: 2 !important;
}

.hot-item-ribbon {
	z-index: 2;
	background-color: red !important;
}

.container-fluid {
	max-width: 100% !important;
}

.container-fluid-default {
	max-width: 90% !important;
}

@media only screen and (min-width: 800px) {
	.item-infor {
		white-space: nowrap;
	}
	.btn-block-ctg {
		margin-bottom: 12px;
	}
	.btn-action-group-item {
		margin-bottom: 10px !important;
		margin-left: 500px !important;
	}
	.group-btn-leftside {
		left: 23px;
		z-index: 3;
		top: 55%;
	}
	.input-search-option {
		width: 30vh;
	}
	.title-new-item {
		max-width: 300px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.title-item-responsive {
		max-width: 300px;
		word-wrap: break-word;
	}
}
@media only screen and (min-width: 1000px) {
	.title-item-responsive {
		max-width: 350px;
		word-wrap: break-word;
	}
}

@media only screen and (min-width: 1150px) {
	.scraper-item {
		z-index: 2;
		right: 105px;
		left: auto !important;
	}
	.item-infor {
		white-space: normal;
		margin-left: 0px;
	}
	.btn-block-ctg {
		margin-bottom: 0px;
	}
	.btn-action-group-item {
		margin-bottom: 0px !important;
		margin-right: 0.5rem !important;
		margin-left: 0px !important;
	}
	.group-btn-leftside {
		left: 25px;
		z-index: 3;
		top: 55%;
	}
	.title-new-item {
		max-width: 400px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

@media only screen and (min-width: 1400px) {
	.scraper-item {
		z-index: 2;
		right: 10vw;
		left: auto !important;
	}
	.input-search-option {
		width: 70vh;
	}
	.title-new-item {
		max-width: 800px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.group-btn-leftside {
		top: 55%;
		left: 25px;
		z-index: 3;
	}
}

.black-screen {
	background-color: rgb(0, 0, 0);
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 9999;
}

.form-control:focus {
	// border: 2px solid #2592ff;
	border-color: #0354e9;
	outline: 0;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.avatar-contact {
	background-color: #6691e7;
	font-weight: 400;
	height: 40px;
	width: 40px;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
}

.notify-icon {
	display: inline-block;
	height: 15px;
	width: 15px;
	background-color: #3d64e6;
	border-radius: 50%;
}
.notify-icon-new {
	display: inline-block;
	height: 15px;
	width: 15px;
	background-color: #d61a1a;
	border-radius: 50%;
}
.unseen {
	background-color: #d9d9d9;
}
.chat-box-single-line {
	border-bottom: 1px solid #a4c6b5;
	height: 12px;
	margin: 7px 0 20px;
	position: relative;
	text-align: center;
}

.chat-box-single-line-new {
	border-bottom: 1px solid #dde3f0;
	height: 12px;
	margin: 7px 0 20px;
	position: relative;
	text-align: center;
}

.chat-mobile-button-footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	border-top: 1px solid #c4c4c4;
	box-sizing: border-box;
}

.chat-room-list-mobile {
	height: calc(100vh - 300px);
}

.chat-message-list-mobile {
	height: calc(100vh - 385px);
}

.chat_subject_mobile {
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 2;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	width: 330px;
}

abbr.timestamp {
	background: #3f9684 none repeat scroll 0 0;
	color: #fff;
	padding: 0 11px;
}
abbr.timestamp-new {
	background: #dde3f0 none repeat scroll 0 0;
	color: #6691e7;
	padding: 0 11px;
	font-weight: bold;
}

#btn-backs-to-bottom {
	position: absolute;
	bottom: 0;
	left: 48%;
	border-radius: 50%;
	background: linear-gradient(#00ff04, #09aad3);
	opacity: 0.2;
	background: #7ac9f9;
}
#btn-backs-to-bottom:hover {
	opacity: 50%;
}

#btn-load-more {
	position: absolute;
	top: 0;
	left: 45%;
	color: #fff;
	display: block;
	text-align: center;
	margin: 7px auto;
	padding: 3px;
	border-radius: 10px;
	border: 1px solid transparent;
	background-color: rgb(99, 99, 182);
	transition: 0.3s;
	z-index: 1;
}
#btn-load-more:hover {
	opacity: 50%;
	color: blue;
	background-color: #fff;
	border: 1px solid blue;
	text-decoration: none;
}

.progress-active-on {
	height: 45px !important;
	background-color: #50c3e6 !important;
	color: #ffffff !important;
	&::after {
		border-left-color: #50c3e6 !important;
	}
}

.progress-active-off {
	height: 45px !important;
	background-color: #878a9940 !important;
	color: #ffffff !important;
	&::after {
		border-left-color: #878a9940 !important;
	}
}

.isDisable {
	pointer-events: none;
	opacity: 0.7;
	-moz-user-focus: none;
	-webkit-user-focus: none;
	-ms-user-focus: none;
	-moz-user-modify: read-only;
	-webkit-user-modify: read-only;
	-ms-user-modify: read-only;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.ck-editor__editable {
	min-height: 150px !important;
	max-height: 500px !important;
}

.react-select__value-container {
	padding: 2px 2px 2px 4px !important;
}
.react-select__indicator {
	padding-left: 3px !important;
	padding-right: 3px !important;
}

.my-custom-popover {
	font-size: 200%;
}

//React Tag
.react-tags {
	position: relative;
	padding: 3px 0px 0px 6px;
	border: 1px solid #d1d1d1;
	border-radius: 4px;

	/* shared font styles */
	font-size: 1em;
	line-height: 1;

	/* clicking anywhere will focus the input */
	cursor: text;
}

.react-tags.is-focused {
	// border-color: #b1b1b1;
	border-color: #0354e9;
	outline: 0;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.react-tags__selected {
	display: inline;
}

.react-tags__selected-tag {
	display: inline-block;
	box-sizing: border-box;
	margin: 3px 6px 0px 3px;
	padding: 4px 3px 6px;
	border: 1px solid #d1d1d1;
	border-radius: 4px;
	background: #eef6fe;

	/* match the font styles */
	font-size: inherit;
	line-height: inherit;
}

.react-tags__selected-tag:after {
	content: "\2715";
	color: #aaa;
	margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
	border-color: #b1b1b1;
}

.react-tags__search {
	display: inline-block;

	/* match tag layout */
	padding: 7px 2px;
	margin-bottom: 4px;

	/* prevent autoresize overflowing the container */
	max-width: 100%;
}

@media screen and (min-width: 30em) {
	.react-tags__search {
		/* this will become the offsetParent for suggestions */
		position: relative;
	}
}

.react-tags__search input {
	/* prevent autoresize overflowing the container */
	max-width: 100%;
	min-width: 130px !important;
	/* remove styles and layout from this element */
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;

	/* match the font styles */
	font-size: inherit;
	line-height: inherit;
}

.react-tags__search input::-ms-clear {
	display: none;
}

.react-tags__suggestions {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
}

@media screen and (min-width: 30em) {
	.react-tags__suggestions {
		width: 240px;
	}
}

.react-tags__suggestions ul {
	margin: 4px -1px;
	padding: 0;
	list-style: none;
	background: white;
	border: 1px solid #d1d1d1;
	border-radius: 2px;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
	border-bottom: 1px solid #ddd;
	padding: 6px 8px;
}

.react-tags__suggestions li mark {
	text-decoration: underline;
	background: none;
	font-weight: 600;
}

.react-tags__suggestions li:hover {
	cursor: pointer;
	background: #eee;
}

.react-tags__suggestions li.is-active {
	background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
	opacity: 0.5;
	cursor: auto;
}

.controls {
	margin: 1rem 0;
}

.blur-input {
	position: absolute;
	left: 50%;
	transform: translate(-50%, -8%);
	z-index: 999;
	height: 300px;
	width: 1200px;
	background-color: rgba(255, 255, 255, 0.4);
	backdrop-filter: blur(5px);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.link-to-conversation {
	pointer-events: none;
}
.rti--input {
	width: 100% !important;
}

.ant-select-selector {
	min-height: 40px !important;
	border-radius: 4px !important;
	border-color: gray !important;
	padding-top: 3px !important;
}

.ant-select-clear {
	top: 40% !important;
}

.ant-select-selector {
	min-height: 38px !important;
	border-radius: 4px !important;
	padding-top: 2px !important;
}

.ant-select-selection-placeholder {
	grid-area: 1/1/2/3 !important;
	color: gray !important;
	margin-left: 2px;
	margin-right: 2px;
	box-sizing: border-box !important;
	font-size: 13.5px;
}

.btn-action-block-button-md {
	height: 28px;
	margin-top: 4px;
}

@media only screen and (min-width: 1400px) {
	.xxl-column-3 {
		column-count: 3 !important;
	}
}

@media only screen and (max-width: 1399px) {
	.table-list-po-res {
		margin-top: 30px !important;
	}
	.xl-mt-0 {
		margin-top: 0px !important;
	}

	.xl-mt-20 {
		margin-top: 20px !important;
	}

	.xl-mt-40 {
		margin-top: 40px !important;
	}
	.xl-column-2 {
		column-count: 2 !important;
	}
}

@media only screen and (max-width: 1199px) {
	.lg-mt-0 {
		margin-top: 0px !important;
	}

	.lg-mt-20 {
		margin-top: 20px !important;
	}

	.lg-mt-40 {
		margin-top: 40px !important;
	}
}

@media only screen and (max-width: 991px) {
	.md-mt-0 {
		margin-top: 0px !important;
	}

	.md-mt-10 {
		margin-top: 10px !important;
	}

	.md-mt-20 {
		margin-top: 20px !important;
	}

	.md-mt-40 {
		margin-top: 40px !important;
	}

	.md-flex-start {
		display: flex !important;
		justify-content: start !important;
	}

	.md-flex-end {
		display: flex !important;
		justify-content: end !important;
	}
	.md-column-1 {
		column-count: 1 !important;
	}

	.md-display-none {
		display: none !important;
	}

	.md-display-initial {
		display: initial !important;
	}
}

@media only screen and (max-width: 767px) {
	.sm-mt-0 {
		margin-top: 0px !important;
	}

	.sm-mt-20 {
		margin-top: 20px !important;
	}

	.sm-mt-40 {
		margin-top: 40px !important;
	}

	.max-width-sm {
		max-width: 767px !important;
	}
}

@media only screen and (max-width: 575px) {
	.xs-mt-0 {
		margin-top: 0px !important;
	}

	.xs-mt-20 {
		margin-top: 20px !important;
	}

	.xs-mt-40 {
		margin-top: 40px !important;
	}
}

.input-group > .form-control:focus {
	z-index: inherit;
}

.model-list {
	> li {
		&.active {
			a {
				background-color: rgb(232, 239, 251);
			}
		}
		a {
			display: block;
			// padding: 7px 24px;
			color: var(--#{$prefix}body-color);
			transition: all 0.4s;
			font-family: $font-family-secondary;
			font-weight: $font-weight-medium;
			font-size: $font-size-base;
		}
	}
}
/* Outer Layer with the timeline border */
.content-timeline {
	position: relative;
}

.content-timeline-line {
	height: 100%;
	margin-left: 8px;
	border-left: 2px solid #ccc;
}

/* Content container */
.content-timeline-content {
	padding-left: 8px;
}

/* Timeline dot  */
.content-timeline-content::before {
	content: "";
	position: absolute;
	width: 0.825rem;
	height: 0.825rem;
	border-radius: 999px;
	background-color: white;
	left: 2px;
	border: 3px solid #759be9;
	margin-top: 4px;
}

//Selected image
.selected-images-list {
	height: 559px;
}

/* Label selected */
.lable-item {
	max-width: 100%;
	width: 230px;
	height: 32px;
	line-height: 32px;
	padding: 0 12px;
	border-radius: 3px;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-weight: 500;
}

/* Color picker */
.color-list-picker {
	width: 100%;
	height: 260px;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	gap: 4px;
	margin-bottom: 8px;
}

.color-container {
	position: relative;
	box-sizing: border-box;
	border-radius: 3px;
	border: 2px transparent solid;
	height: 32px;
}

.color-element {
	position: relative;
	border-radius: 3px;
	width: 100%;
	height: 100%;
	background-color: #0ba080;
	cursor: pointer;
}

.table-responsive > table > thead {
	height: 45px;
}

.table-responsive > table > tbody > tr > td {
	padding: 0.45rem 0.45rem;
}

.flatpickr-log-time {
	padding: 0.2rem 0.9rem;
}

.chat-text-description {
	width: 327px;
	margin-bottom: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.chat-text-description-new {
	width: 358px;
	margin-bottom: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}
.line-numbers {
	background-color: #f3f6f9;
}

.log-area > span {
	color: #000103;
	text-shadow: none;
	font-family: Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace;
	font-size: 14px;
}

.accordion-padding-0 .accordion-button {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	color: #000000;
	background-color: #ffffff;
}

.accordion-padding-0 .accordion-button::after {
	top: 4px !important;
}

.accordion-padding-1 .accordion-button {
	padding-top: 8px !important;
	padding-bottom: 8px !important;
	padding-right: 0px !important;
	color: #000000;
	background-color: #ffffff;
}

.accordion-padding-1 .accordion-button::after {
	top: 16px !important;
}

.accordion-body-color-unset .accordion-body {
	color: unset !important;
	padding: 8px 8px 4px !important;
}

.modal-bg-none > .modal-content {
	background-color: transparent !important;
}

.sk-three-bounce > div {
	width: 8px !important;
	height: 8px !important;
}

.ball-clip-rotate-multiple > div:first-child {
	width: 60px !important;
	height: 60px !important;
	left: -35px !important;
	top: -35px !important;
}

.ball-clip-rotate-multiple > div:last-child {
	width: 30px !important;
	height: 30px !important;
	left: -20px !important;
	top: -20px !important;
}

.disabled-link {
	pointer-events: none;
}

fieldset,
legend {
	all: revert;
}

.file-viewer-overflow-hidden > .pg-viewer-wrapper {
	overflow: hidden !important;
}

.file-viewer-overflow-hidden > .pg-viewer-wrapper > .pg-viewer > .pdf-viewer-container > .pdf-viewer > .pdf-canvas {
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
}

.react-select-sm .react-select__dropdown-indicator {
	padding: 2px !important;
}
.react-select-sm svg {
	height: 13px;
	width: 13px;
}

.hover-cursor {
	transition: opacity 0.6s ease !important;
	-webkit-transition: opacity 0.6s ease !important;
	-moz-transition: opacity 0.6s ease !important;
	-o-transition: opacity 0.6s ease !important;
	pointer-events: auto !important;
}

.hover-cursor:hover {
	opacity: 0.7 !important;
	cursor: pointer !important;
}

.hover-link:hover {
	opacity: 0.8 !important;
	cursor: pointer !important;
	color: #3182ce;
	text-decoration: underline;
}

.file-viewer-style-task {
	width: 147px;
	height: 147px;
	object-fit: contain;
}

.highLight-text {
	background-color: yellow;
}

.animation-rotate {
	animation: rotation 1.5s infinite linear;
}

@keyframes rotation {
	from {
		transform: rotate(359deg);
	}
	to {
		transform: rotate(0deg);
	}
}

.table-row-hover:hover {
	color: rgba(0, 0, 0, 0.87) !important;
	background-color: #d9d9d9 !important;
	-webkit-transition-duration: 0.15s !important;
	transition-duration: 0.15s !important;
	-webkit-transition-property: background-color !important;
	transition-property: background-color !important;
	outline-style: solid !important;
	outline-width: 1px !important;
	outline-color: #ffffff !important;
	cursor: pointer !important;
}

.tableCustom-tbody:hover {
	background-color: #e0e0e0 !important;
}

.__react_component_tooltip {
	max-width: 1000px;
	// max-height: 800px;
	// text-overflow: ellipsis;
	// overflow-y: auto;
	z-index: 2000 !important;
}

.filepond--root {
	margin-bottom: 0 !important;
}
.filepond--item {
	width: calc(20% - 0.5em);
	height: 50px;
}

.filepond--drop-label {
	background-color: #e8effb;
	color: #0084ff !important;
}

.filepond--panel-root {
	background-color: #e8effb !important;
}

.filepond--item-panel {
}

.input-mention {
	input {
		@extend .form-control;
		position: absolute !important;
		top: 0 !important;
	}
	textarea {
		@extend .form-control;
		// resize: vertical !important;
		// position: relative !important;
	}
}

// .nav-item-custom {
// 	border: 1px solid black;
// 	margin-right: 20px;
// }

// .nav-tabs-custom .nav-item .nav-link.active {
// 	color: black;
// 	background-color: #dae8fc;
// 	border-color: #dae8fc;
// }

.row-data-custom:hover {
	background-color: aliceblue;
	cursor: pointer;
}

.input-select-option {
	// height: 40px;
	// width: 100%;
	// display: flex;
	// align-items: center;
	// padding-left: 8px;

	width: 100%;
	display: flex;
	align-items: center;
	padding: 8px;
	border-bottom: 1px solid gainsboro;
}
.input-select-option:hover {
	cursor: pointer;
	background-color: #d2e0fb;
}

.input-select-list {
	position: absolute;
	z-index: 5;
	background-color: #ffffff;
	max-height: 250px;
	overflow-y: auto;
	width: 100%;
	border-radius: 4px;
	box-shadow: 0px 0px 3px;
	margin-top: 4px;
}
