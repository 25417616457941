.tagCustom-container {
	width: 100%;
	display: flex;
	min-height: 38px;
	border: 1px solid gray;
	border-radius: 5px;
	&:hover {
		border: 1px solid hsl(0, 0%, 70%);
	}
}
.tagCustom-box {
	flex: 1;
	display: flex;
	flex-wrap: wrap;
	min-height: 38px;
	padding: 4px 6px;
}
.tagCustom-removeAll {
	width: 36px;
	margin: auto;
	// display: flex;
	// align-items: center;
	// justify-content: center;
	color: rgb(173, 173, 173);
}

.tagCustom {
	display: flex;
	align-items: center;
	padding-left: 5px;
	padding-right: 2px;
	border: 1px solid rgb(173, 173, 173);
	border-radius: 4px;
	height: 25px;
	margin: 2px 5px 2px 0px;
	background-color: #eef6fe;

	&:hover {
		box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
	}

	&-value {
		margin-right: 10px;
		white-space: nowrap;
		max-width: 100px;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&-input {
		border: none;
		flex: 1;
		outline: none;
		padding: 5;
		background-color: none;
		width: 50%;
		min-width: 50px;
	}
	&-remove {
		margin-left: 5px;
		cursor: pointer;
	}
}
