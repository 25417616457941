.taskCustom-disableAllPage {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 9999;
	background-color: rgba(82, 82, 82, 0.5);
}

.taskCustom-selectPriority {
	// width: 100%;
	display: inline-block !important;
	padding: 2px 2px;
	margin: 3px 0px;
	text-align: center;
	border: none;
	outline: none;
	background: unset;
	display: block;
	// text-align: center;
	/* for Firefox */
	-moz-appearance: none;
	/* for Chrome */
	-webkit-appearance: none;
	::-ms-expand {
		display: none;
	}
}

.taskCustom-zIndex {
	z-index: 9999;
}

.taskCustom-btnAdd {
	background-color: rgba(12, 102, 228, 1);
	max-width: 100px;
	padding: 5px 8px;
	height: 30px;
	color: #ffffff;
	display: flex;
	align-items: center;
	border-radius: 6px;
	text-align: center;
	font-weight: 600;
	&:hover {
		background-color: rgb(4, 82, 192);
	}
}
.taskCustom-iconX {
	display: flex;
	align-items: center;
	text-align: center;
	font-weight: 600;
	font-size: 20px;
	&:hover {
		opacity: 0.7;
	}
}

.taskCustom-container {
	max-height: "100vh";
	max-width: "100vw";
	overflow-x: "auto";
}

.taskCustom-btnAddList {
	width: 270px;
	height: 40px;
	background-color: rgba(191, 191, 191, 0.5);
	color: #ffffff;
	padding: 5px 5px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	font-weight: 500;
	&:hover {
		background-color: rgba(191, 191, 191, 0.2);
	}
}

.taskCustom-InputAddList {
	background-color: #ffffff;
	padding: 10px;
	border-radius: 10px;
}

.taskCustom-column {
	display: flex;
	.taskCustom-columnContainer {
		height: fit-content;
		margin: 0 5px;
		display: flex;

		flex-direction: column;
		background: #f3f6f9;
		// background: "unset";
		flex-shrink: 0;
		width: 305px;
		height: fit-content;
		border-radius: 12px;
		border: 1px solid rgba(0, 0, 0, 0.12);
		padding: 5px 0px 1px 3px;

		// -webkit-box-sizing: border-box;
		// -moz-box-sizing: border-box;
		box-sizing: border-box;
		cursor: pointer !important;

		.taskCustom-columnItem {
			padding: 0px 8px 0px 8px;
			transition: background-color ease 0.2s;
			border-radius: 5px;
			cursor: pointer !important;
			min-height: 60px;
		}

		.taskCustom-btnAddCard {
			width: 100%;
			height: 40px;
			padding: 0 10px 0 5px;
			border-radius: 10px;
			display: flex;
			align-items: center;
			&:hover {
				background-color: rgba(191, 191, 191, 0.5);
			}
		}
		.taskCustom-editTitleColumn {
			height: 50px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.taskCustom-titleColumn {
			height: 50px;
			padding: 10px 15px;
			font-weight: 500;
			font-size: 15px;
			display: flex;
			align-items: center;
		}

		.taskCustom-inputEditTitleColumn {
			height: 30px;
			font-size: 15px;
			padding: 0 6px;
			font-weight: 500;
		}
	}
}

.taskCustom-task {
	position: relative;
	cursor: pointer !important;
	margin-bottom: 8px;
	border-radius: 6px;
	// min-height: 45px;
	padding: 10px;
	// background: ${(props) => (props.isDragging ? "#cfd1d4" : "white")};
	box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;

	.taskCustom-taskBackgroundDropFile {
		position: absolute;
		inset: 0;
		background-color: #f3f6f9;
		z-index: 999999;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 6px;
	}
	.taskCustom-taskIconRemove {
		position: absolute;
		display: block;
		top: 3px;
		right: 5px;
		padding: 0px 3px;
		border-radius: 5px;
	}
	.taskCustom-taskIconRemove:hover {
		background-color: rgba(191, 191, 191, 0.5);
	}
	&:hover {
		background: #f1f2f4;
		.taskCustom-taskTitle {
			.taskCustom-taskIconEdit {
				visibility: visible;
			}
			.taskCustom-taskIconEdit:hover {
				background-color: rgba(191, 191, 191, 0.5);
			}
		}
	}
	.taskCustom-taskProject {
		font-weight: 600;
		padding: 0px 1px;
		&:hover {
			background-color: rgba(0, 0, 0, 0.2);
			font-weight: 620;
			border-radius: 2px;
		}
	}
	.taskCustom-taskTitle {
		// min-height: 45px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
	}
	.taskCustom-taskIconEdit {
		visibility: hidden;
		padding: 2px 4px;
		border-radius: 5px;
	}
	.taskCustom-inputEditTask {
		position: absolute;
		top: -5px;
		left: -10px;
		border-radius: 6px;
		background-color: #ffffff;
		z-index: 9999;
		padding: 10px;
	}
	.taskCustom-badgeInforNumTask {
		background-color: #1f845a;
		color: #ffffff;
		display: flex;
		justify-content: space-around;
		align-items: center;
		width: fit-content;
		padding: 1px 6px;
		border-radius: 6px;
	}
}

.taskCustom-sendtoboard {
	cursor: pointer;
	&:hover {
		opacity: 0.7;
	}
}

.taskCustom-iconOpenArchiveList {
	position: fixed;
	bottom: 30px;
	right: 30px;
	background: #5b5b5b;
	width: 35px;
	height: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	cursor: pointer;
	&:hover {
		opacity: 0.8;
	}
}
