// :root CSS variables

:root {
	//vertical light
	--#{$prefix}vertical-menu-bg: #{$white};
	--#{$prefix}vertical-menu-item-color: #{darken($gray-600, 10%)};
	--#{$prefix}vertical-menu-item-hover-color: #{$primary};
	--#{$prefix}vertical-menu-item-active-color: #{$primary};
	--#{$prefix}vertical-menu-sub-item-color: #{darken($gray-600, 4%)};
	--#{$prefix}vertical-menu-sub-item-hover-color: #{$primary};
	--#{$prefix}vertical-menu-sub-item-active-color: #{$primary};
	--#{$prefix}vertical-menu-title-color: #919da9;

	//vertical dark
	--#{$prefix}vertical-menu-bg-dark: #38454a;
	--#{$prefix}vertical-menu-item-color-dark: #a1b7bf;
	--#{$prefix}vertical-menu-item-hover-color-dark: #{$white};
	--#{$prefix}vertical-menu-item-active-color-dark: #{$white};
	--#{$prefix}vertical-menu-sub-item-color-dark: #a1b7bf;
	--#{$prefix}vertical-menu-sub-item-hover-color-dark: #{$white};
	--#{$prefix}vertical-menu-sub-item-active-color-dark: #{$white};
	--#{$prefix}vertical-menu-title-color-dark: #a1b7bf;

	// Topbar - (Default Light)
	--#{$prefix}header-bg: #{$white};
	--#{$prefix}header-item-color: #e9ecef;
	// Topbar - Dark
	--#{$prefix}header-bg-dark: #38454a;
	--#{$prefix}header-item-color-dark: #9ea9ad;

	// Topbar Search
	--#{$prefix}topbar-search-bg: #f3f3f9;
	// Topbar User
	--#{$prefix}topbar-user-bg: #f3f3f9;
	--#{$prefix}topbar-user-bg-dark: #455358;

	//footer
	--#{$prefix}footer-bg: #{$white};
	--#{$prefix}footer-color: #98a6ad;

	// Horizontal nav
	--#{$prefix}topnav-bg: #{$white};
	--#{$prefix}topnav-item-color: #{darken($gray-600, 10%)};
	--#{$prefix}topnav-item-color-active: #{$primary};

	// twocolumn menu
	--#{$prefix}twocolumn-menu-iconview-bg: #{$white};
	--#{$prefix}twocolumn-menu-bg: #{$white};
	// two column dark
	--#{$prefix}twocolumn-menu-iconview-bg-dark: var(--#{$prefix}vertical-menu-bg-dark);
	--#{$prefix}twocolumn-menu-bg-dark: #3d494e;
	--#{$prefix}twocolumn-menu-item-color-dark: var(--#{$prefix}vertical-menu-item-color-dark);
	--#{$prefix}twocolumn-menu-item-active-color-dark: #{$white};
	--#{$prefix}twocolumn-menu-item-active-bg-dark: #{rgba($white, 0.15)};

	// boxed
	--#{$prefix}boxed-body-bg: #{darken($body-bg, 4%)};

	// heading-color
	--#{$prefix}heading-color: #{$gray-700};

	// component variable
	// link
	--#{$prefix}link-color: #{$primary};
	--#{$prefix}link-hover-color: #{$primary};

	// Border variable
	--#{$prefix}border-color: #{$gray-300};

	// card
	--#{$prefix}card-bg-custom: #{$white};
	--#{$prefix}card-logo-dark: block;
	--#{$prefix}card-logo-light: none;

	// nav tabs
	.nav-tabs {
		--#{$prefix}nav-tabs-link-active-bg: #{$body-bg};
	}

	//choices
	.choices,
	.flatpickr-calendar,
	.ck,
	.ql-toolbar,
	.select2-container,
	.menu-dropdown {
		--#{$prefix}choices-bg: #{$white};
		--#{$prefix}choices-link-color: #{$gray-900};
		--#{$prefix}choices-link-hover-bg: #{$gray-100};
		--#{$prefix}choices-link-active-color: #{shade-color($gray-900, 10%)};
	}

	//list
	--#{$prefix}list-group-hover-bg: #{$gray-100};

	// form
	--#{$prefix}input-bg: #{$white};
	--#{$prefix}input-border: #{$gray-400};
	--#{$prefix}input-focus-border: #{tint-color($component-active-bg, 50%)};
	--#{$prefix}input-disabled-bg: #{$gray-200};

	// input-group-addon
	--#{$prefix}input-group-addon-bg: #{$gray-200};

	//check
	--#{$prefix}input-check-border: var(--#{$prefix}input-border);
}

//---------------------------------------//
//Theme Light
[data-layout-mode="light"] {
	.selectMerge {
		background-color: #d7dde1;
	}
	.selectRowData {
		background-color: #e3f2fd;
		color: #000000;
	}
	.new-item-title {
		color: rgb(2, 2, 191);
		font-size: 1rem;
	}
	.new-item-seller {
		color: #6691e7;
		font-weight: 700;
		font-size: 1rem;
	}
	.MuiFormLabel-root {
		padding: 1px 0px 6px 6px !important;
		font-size: 0.825rem !important;
	}
	.MuiInputBase-input {
		font-size: 0.825rem !important;
	}

	.backgroud-search-vpn {
		background-color: #f3f6f9;
	}
	.title-table-vpn {
		font-size: 0.825rem;
		font-weight: bold;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 100%;
		text-align: center;
		background-color: #f3f6f9;
	}

	.title-table-market {
		font-size: 0.825rem;
		font-weight: bold;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 100%;
		text-align: center;
		z-index: 1;
		position: inherit;
		margin-top: -23px;
	}

	[data-column-id="ColumnBgSuccess1"].rdt_TableCol {
		background-color: #d0f3e1 !important;
	}
	[data-column-id="ColumnBgSuccess2"].rdt_TableCol {
		background-color: #d0f3e1 !important;
	}
	[data-column-id="ColumnBgSuccess3"].rdt_TableCol {
		background-color: #d0f3e1 !important;
	}
	[data-column-id="ColumnBgSuccess4"].rdt_TableCol {
		background-color: #d0f3e1 !important;
	}
	[data-column-id="ColumnBgSuccess5"].rdt_TableCol {
		background-color: #d0f3e1 !important;
	}
	[data-column-id="ColumnBgSuccess6"].rdt_TableCol {
		background-color: #d0f3e1 !important;
	}
	[data-column-id="ColumnBgSuccess7"].rdt_TableCol {
		background-color: #d0f3e1 !important;
	}
	[data-column-id="ColumnBgSuccess8"].rdt_TableCol {
		background-color: #d0f3e1 !important;
	}
	[data-column-id="ColumnBgSuccess9"].rdt_TableCol {
		background-color: #d0f3e1 !important;
	}
	[data-column-id="ColumnBgSuccess10"].rdt_TableCol {
		background-color: #d0f3e1 !important;
	}

	[data-column-id="ColumnBgPrimary1"].rdt_TableCol {
		background-color: #e0e9fa !important;
	}
	[data-column-id="ColumnBgPrimary2"].rdt_TableCol {
		background-color: #e0e9fa !important;
	}
	[data-column-id="ColumnBgPrimary3"].rdt_TableCol {
		background-color: #e0e9fa !important;
	}
	[data-column-id="ColumnBgPrimary4"].rdt_TableCol {
		background-color: #e0e9fa !important;
	}
	[data-column-id="ColumnBgPrimary5"].rdt_TableCol {
		background-color: #e0e9fa !important;
	}
	[data-column-id="ColumnBgPrimary6"].rdt_TableCol {
		background-color: #e0e9fa !important;
	}
	[data-column-id="ColumnBgPrimary7"].rdt_TableCol {
		background-color: #e0e9fa !important;
	}
	[data-column-id="ColumnBgPrimary8"].rdt_TableCol {
		background-color: #e0e9fa !important;
	}
	[data-column-id="ColumnBgPrimary9"].rdt_TableCol {
		background-color: #e0e9fa !important;
	}
	[data-column-id="ColumnBgPrimary10"].rdt_TableCol {
		background-color: #e0e9fa !important;
	}

	[data-column-id="ColumnBgWarning1"].rdt_TableCol {
		background-color: #e8bc53 !important;
	}
	[data-column-id="ColumnBgWarning2"].rdt_TableCol {
		background-color: #e8bc53 !important;
	}
	[data-column-id="ColumnBgWarning3"].rdt_TableCol {
		background-color: #e8bc53 !important;
	}
	[data-column-id="ColumnBgWarning4"].rdt_TableCol {
		background-color: #e8bc53 !important;
	}
	[data-column-id="ColumnBgWarning5"].rdt_TableCol {
		background-color: #e8bc53 !important;
	}
	[data-column-id="ColumnBgWarning6"].rdt_TableCol {
		background-color: #e8bc53 !important;
	}
	[data-column-id="ColumnBgWarning7"].rdt_TableCol {
		background-color: #e8bc53 !important;
	}
	[data-column-id="ColumnBgWarning8"].rdt_TableCol {
		background-color: #e8bc53 !important;
	}
	[data-column-id="ColumnBgWarning9"].rdt_TableCol {
		background-color: #e8bc53 !important;
	}
	[data-column-id="ColumnBgWarning10"].rdt_TableCol {
		background-color: #e8bc53 !important;
	}

	.table-light-add-row {
		background-color: #b7cbf1 !important;
	}

	.rdt_TableCol {
		background-color: #b7cbf1 !important;
	}

	.rdt_TableHeadRow {
		background-color: #b7cbf1 !important;
	}
}
