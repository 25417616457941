//
// _list-emails.scss
//

//Font Lato
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;500;600;700&display=swap");

.font-lato {
	font-family: "Lato", sans-serif;
}

// body {
// 	overflow: hidden !important;
// 	height: 100vh;
// }

.list-emails-sort {
	display: block;
	height: 30px;
	line-height: 30px;
	top: 0px;
	position: sticky;
	z-index: 1;
	background-color: var(--#{$prefix}card-bg-custom);

	@media (min-width: 0px) {
		div:hover {
			cursor: pointer;
			background-color: #ffffff;
		}

		.star-title {
			text-align: center;
			width: 45px;
		}

		.attachment-title {
			border-left: 1px solid #e8e8e8;
			text-align: center;
			width: 45px;
		}

		.account-title {
			border-left: 1px solid #e8e8e8;
			width: 10%;
			min-width: 45px;
			padding-left: 8px;
		}

		.correspondent-title {
			border-left: 1px solid #e8e8e8;
			width: 20%;
			min-width: 90px;
			padding-left: 8px;
		}

		.subject-title {
			border-left: 1px solid #e8e8e8;
			width: 30%;
			min-width: 150px;
			padding-left: 8px;
			flex-grow: 1;
		}

		.unread-title {
			border-left: 1px solid #e8e8e8;
			width: 70px;
			text-align: center;
		}

		.date-title {
			border-left: 1px solid #e8e8e8;
			width: 12%;
			padding-left: 8px;
		}

		.tags-title {
			border-left: 1px solid #e8e8e8;
			width: 80px;
			padding-left: 8px;
		}
	}
	@media (min-width: 1280px) {
		div:hover {
			cursor: pointer;
			background-color: #eeeeee;
		}

		.star-title {
			text-align: center;
			width: 60px;
		}

		.attachment-title {
			border-left: 1px solid #e8e8e8;
			text-align: center;
			width: 60px;
		}

		.account-title {
			border-left: 1px solid #e8e8e8;
			width: 10%;
			min-width: 50px;
			padding-left: 16px;
		}

		.correspondent-title {
			border-left: 1px solid #e8e8e8;
			width: 20%;
			min-width: 100px;
			padding-left: 16px;
		}

		.subject-title {
			border-left: 1px solid #e8e8e8;
			width: 30%;
			min-width: 150px;
			padding-left: 16px;
			flex-grow: 1;
		}

		.unread-title {
			border-left: 1px solid #e8e8e8;
			width: 70px;
			text-align: center;
		}

		.date-title {
			border-left: 1px solid #e8e8e8;
			width: 12%;
			padding-left: 16px;
		}

		.tags-title {
			border-left: 1px solid #e8e8e8;
			width: 8%;
			padding-left: 16px;
		}
	}
}

.list-emails-detail-content {
	position: absolute;
	top: 48% !important;
	width: 100% !important;
	background-color: var(--#{$prefix}card-bg-custom);
	transform: translateY(200%);
	transition: all 0.2s;
	visibility: hidden;
	display: none;
}

.list-emails-message-list {
	padding-left: 0;
	margin: 0;
	@media (min-width: 0px) {
		li {
			position: relative;
			display: flex;
			height: 35px;
			line-height: 35px;
			cursor: default;
			transition-duration: 0.3s;
			// clear: both;
			font-size: 13px;

			a {
				color: var(--#{$prefix}gray-700);
			}

			&.active,
			&.active:hover {
				box-shadow: inset 3px 0 0 $primary;
				background-color: var(--#{$prefix}light);
			}

			&.unread {
				color: var(--#{$prefix}gray-800);

				a {
					color: var(--#{$prefix}gray-800);
					font-weight: 600;
				}
			}

			&:hover {
				background: var(--#{$prefix}light);
				transition-duration: 0.05s;
			}
			.star-content {
				// padding-left: 16px;
				width: 45px;
			}

			.attachment-content {
				width: 45px;
				padding-left: 8px;
			}

			.account-content {
				width: 10%;
				min-width: 45px;
				padding-left: 8px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.correspondent-content {
				width: 20%;
				min-width: 90px;
				padding-left: 8px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.subject-content {
				width: 30%;
				min-width: 150px;
				padding-left: 8px;
				flex-grow: 1;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.unread-content {
				width: 70px;
				text-align: center;
			}

			.date-content {
				width: 12%;
				padding-left: 8px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.tags-content {
				width: 80px;
				padding-left: 8px;
			}
		}
	}
	@media (min-width: 1280px) {
		li {
			position: relative;
			display: flex;
			height: 35px;
			line-height: 35px;
			cursor: default;
			transition-duration: 0.3s;
			// clear: both;
			font-size: 13px;

			a {
				color: var(--#{$prefix}gray-700);
			}

			&.active,
			&.active:hover {
				box-shadow: inset 3px 0 0 $primary;
				background-color: var(--#{$prefix}light);
			}

			&.unread {
				color: var(--#{$prefix}gray-800);

				a {
					color: var(--#{$prefix}gray-800);
					font-weight: 600;
				}
			}

			&:hover {
				background: var(--#{$prefix}light);
				transition-duration: 0.05s;
			}
			.star-content {
				// padding-left: 16px;
				width: 60px;
			}

			.attachment-content {
				width: 60px;
				padding-left: 16px;
			}

			.account-content {
				width: 10%;
				min-width: 50px;
				padding-left: 16px;
			}

			.correspondent-content {
				width: 20%;
				min-width: 100px;
				padding-left: 16px;
			}

			.subject-content {
				width: 30%;
				min-width: 150px;
				padding-left: 16px;
				flex-grow: 1;
			}

			.unread-content {
				width: 70px;
				text-align: center;
			}

			.date-content {
				width: 12%;
				padding-left: 16px;
				// white-space: nowrap;
				// overflow: hidden;
				// text-overflow: ellipsis;
			}

			.tags-content {
				width: 8%;
				padding-left: 16px;
			}
		}
	}
}

.hover-btn:hover {
	background-color: #d3d3d3 !important;
}

// .list-emails-quote-content {
// 	height: 30vh !important;
// }

.list-emails-filter-type {
	width: 360px;
	button {
		&.active {
			background-color: #6691e7 !important;
			color: #ffffff !important;
		}
		&.active:hover {
			background-color: #597fca !important;
		}
	}
}

.list-emails-list-files {
	position: sticky;
	bottom: -1px;
	z-index: 1;
	background-color: var(--#{$prefix}card-bg-custom);
	border: 1px solid #e9ebec;
}

.list-files-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.list-files-content {
	display: flex;
	flex-wrap: wrap;
}

.list-files-details {
	width: 290px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 4px 0 4px;
}

.list-files-details:hover {
	cursor: pointer;
	background-color: #eeeeee;
}

#list-email-selectionText {
	::selection {
		background: gold;
	}
}

.react-select__menu-portal {
	z-index: 10 !important;
}

.btn-option-search {
	background-color: #ffffff !important;
	border: 0 !important;

	&:hover {
		background-color: #e8e8e8 !important;
		// border: 1px solid #ccc !important;
	}

	&.include {
		background-color: #e8e8e8 !important;
		border: 1px solid #ccc !important;

		&:hover {
			background-color: #d3d3d3 !important;
		}
	}
}

.w-90 {
	width: 90%;
}

.list-emails-wrapper {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	background-color: #fefefe;

	.list-emails-content {
		height: 90vh !important;
		transition: all 0.5s ease;
	}
	.list-emails-content.list-emails-detail-show {
		height: 38.5vh !important;
	}
}

.list-emails-wrapper:has(.list-emails-filter-messages-by) {
	.list-emails-content {
		height: 87vh !important;
	}
	.list-emails-content.list-emails-detail-show {
		height: 38.5vh !important;
	}
}

.list-emails-detail {
	width: 99vw;
	height: 55vh;
	position: relative;
	bottom: 0px;
	border-radius: 6px;
	background: #fff;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

	overflow: hidden;
	transform: translateY(calc(100% + 30px));
	/*mượt */
	/* transition: all 0.5s ease;  */
	/*Nhảy tango */
	transition: all 0.5s ease;
}
.list-emails-detail.list-emails-detail--active {
	transform: translateY(0);
	height: 60vh;
	.list-emails-detail-content-scroll {
		height: 50vh;
		overflow-y: scroll;
	}
}

.list-emails-iconClose {
	position: fixed;
	right: 20px;
	top: 10px;
	padding: 3px 5px;
	border-radius: 4px;

	&:hover {
		cursor: pointer;
		background-color: #870404;
		color: #ffffff;
		right: 20px;
		top: 10px;
	}
}
