//
// _to-do.scss
//

// .to-do-menu {
// 	li {
// 		.nav-link {
// 			color: var(--#{$prefix}body-color);
// 			padding-left: 0;
// 			font-weight: $font-weight-medium;
// 			background-color: var(--#{$prefix}light);
// 			padding: 10px 12px;
// 			border-radius: 4px;
// 			display: block;
// 			margin-bottom: 8px;
// 			&:hover,
// 			&.active {
// 				color: $success;
// 			}
// 		}
// 	}
// }

// .todo-content {
// 	height: calc(100vh - 362px);
// 	overflow-y: auto;
// 	&::-webkit-scrollbar {
// 		-webkit-appearance: none;
// 	}

// 	&::-webkit-scrollbar:vertical {
// 		width: 8px;
// 	}

// 	&::-webkit-scrollbar:horizontal {
// 		height: 10px;
// 	}

// 	&::-webkit-scrollbar-thumb {
// 		background-color: rgba(var(--#{$prefix}dark-rgb), 0.075);
// 		border-radius: 8px;
// 	}

// 	&::-webkit-scrollbar-track {
// 		border-radius: 8px;
// 	}
// }

// .todo-task {
// 	table {
// 		.form-check {
// 			.form-check-input {
// 				&:checked + .form-check-label {
// 					text-decoration: line-through;
// 				}
// 			}
// 		}
// 	}
// }

// .task-handle {
// 	cursor: move;
// }

// #task-error-msg {
// 	display: none;
// }

///////////////////////////////////////////////
// ReactTableDataComponent

// .react-select__menu {
// 	z-index: 1000 !important;
// }
//listTaskFilter

#listTaskFilter {
	.react-select-multi-line__menu-portal {
		z-index: 10 !important;
	}
}
#listTaskDataTableComponent {
	#row-0 {
		&:has(input[name="select-row-undefined"]) {
			background-color: rgba(183, 203, 241, 0.35);
		}
		input[name="select-row-undefined"] {
			visibility: hidden;
		}
	}
	#row-0 {
		div[id*="-undefined"] {
			align-items: start;
			margin-top: 10px;
			margin-bottom: 5px;
		}
	}
	.rdt_TableHead {
		z-index: 2 !important;
		background-color: rgba(183, 203, 241, 0.35);
	}

	.dropdown-menu {
		z-index: 12 !important;
	}
}
