.sortOption {
	visibility: hidden;
}

.titleSortOption {
	cursor: pointer;
	&:hover {
		.sortOption {
			visibility: visible !important;
		}
	}
}
